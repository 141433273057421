import { SyntheticEvent } from 'react';
import useAxiosMutation from 'hooks/useAxiosMutation';
import { useQueryClient } from 'react-query';
import { MY_PROJECTS_KEY } from 'services/gql/projects';
import { useAxiosQuery } from 'hooks/useAxiosQuery';
import { FavouriteProject } from 'types';

export const useFavouriteProjects = () => {
  const queryClient = useQueryClient();
  const key = [MY_PROJECTS_KEY, 'favourites'];

  const { data: favouriteProjects } = useAxiosQuery<FavouriteProject[]>(
    key,
    '/projects/my-projects/favourites/'
  );

  const { mutateAsync, isLoading } = useAxiosMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });

  const addFavourite = (e: SyntheticEvent, projectId: number) => {
    e.preventDefault();
    mutateAsync({
      method: 'POST',
      path: `/projects/my-projects/${projectId}/favourites/`,
    });
  };

  const removeFavourite = (e: SyntheticEvent, projectId: number) => {
    e.preventDefault();
    mutateAsync({
      method: 'DELETE',
      path: `/projects/my-projects/${projectId}/favourites/`,
    });
  };

  return { favouriteProjects, addFavourite, removeFavourite, isLoading };
};

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import RolesMainIcon from '@mui/icons-material/LabelOutlined';
import { Cpu, Users, Home, Bell, Search, Menu, MapPin } from 'react-feather';
import SettingsApplicationsOutlined from '@mui/icons-material/SettingsApplicationsOutlined';
import DevicesIcon from '@mui/icons-material/Devices';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import Settings from '@mui/icons-material/Settings';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Delete from '@mui/icons-material/Delete';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import SpeedIcon from '@mui/icons-material/Speed';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnitControl from './UnitControlIcon';
import Project from './ProjectIcon';
import OfficeFolder from './OfficeFolder';
import GraphsIcon from './GraphsIcon';
import Analytics from './Analytics';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import BlockIcon from '@mui/icons-material/Block';
import Report from './Report';
import StarIcon from '@mui/icons-material/Star';

/* Domains */
export const ProjectIcon = Project;
export const ManagementIcon = OfficeFolder;
export const AnalyticsIcon = Analytics;
export const UnitControlIcon = UnitControl;

export const UnitIcon = Cpu;
export const HomeIcon = Home;
export const MenuIcon = Menu;
export const NotificationsIcon = Bell;
export const SearchIcon = Search;
export const UsersIcon = Users;
export const OrganisationIcon = BusinessRoundedIcon;
export const RoleIcon = RolesMainIcon;
export const WatcherTypeIcon = SettingsApplicationsOutlined;
export const DeviceModelIcon = DevicesIcon;
export const OverviewIcon = TableChartOutlinedIcon;
export const SettingsIcon = Settings;
export const GraphIcon = GraphsIcon;
export const WarningIcon = ErrorOutlineIcon;
export const DeleteIcon = Delete;
export const MoreIcon = MoreHorizIcon;
export const CommentIcon = ChatBubbleOutlineIcon;
export const PrivateIcon = BlockIcon;
export const ReportIcon = Report;
export const FavouriteIcon = StarIcon;

/* Assets */
export const BatteryIcon = BatteryFullIcon;
export const GreenerEyeIcon = VisibilityOutlinedIcon;
export const EnergyMeterIcon = SpeedIcon;
export const LocationIcon = MapPin;

/* Connection */
export const WifiOnIcon = WifiTetheringIcon;
export const WifiOffIcon = PortableWifiOffIcon;

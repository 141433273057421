const defaultStyles = {
  fontFamily: 'Source Sans Pro',
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
  },
  button: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  overline: {
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: '0.875rem',
  },
  h4: {
    fontWeight: 600,
  },
  h5: {
    fontSize: '1.375rem',
    fontWeight: 600,
  },
};

export default defaultStyles;

import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Outlet } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { Grid, Button, Typography } from '@mui/material';
import { MY_PROJECTS_KEY } from 'services/gql/projects';
import { canCreateProjects } from 'utils/authorization';
import {
  getComingProjects,
  getCurrentProjects,
  getPastProjects,
  PROJECTS_VIEWS,
} from './Projects.utils';
import { Project, FavouriteProject } from 'types';
import { ProjectsFilter } from './components/ProjectsFilter';
import { useFilter } from 'features/filters/hooks/useFilter';
import { FilterAliasMap } from 'features/filters/hooks/useFilter/useFilter.types';
import { useBreakpoint } from 'hooks/useBreakpoint';
import SpaceDashboardOutlinedIcon from 'components/icons/SpaceDashboardOutlined';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { ToggleButtonGroup } from 'components/buttons/ToggleButtonGroup';
import { Breadcrumb } from 'components/layout/Breadcrumb';
import { useAxiosQuery } from 'hooks/useAxiosQuery';
import { useFavouriteProjects } from 'features/favourites/hooks/useFavouriteProjects/useFavouriteProjects';

const filterAliasMap: FilterAliasMap = {
  project: {
    properties: [
      'name',
      'projectOrganisation.organisation.name',
      'projectGreenerUnits.greenerUnit.name',
      'projectGreenerUnits.greenerUnit.externalIdentifier',
    ],
    condition: 'OR',
  },
};

export const ProjectsTopbar = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { width } = useBreakpoint();
  const [projectsView, setProjectView] = useLocalStorage(
    'projectView',
    PROJECTS_VIEWS.CARD
  );

  const { data: myProjects } = useAxiosQuery<Project[]>(
    [MY_PROJECTS_KEY],
    '/projects/my-projects/'
  );

  const { favouriteProjects } = useFavouriteProjects();

  const currentProjects = useMemo(
    () => getCurrentProjects(myProjects ?? []),
    [myProjects]
  );

  const pastProjects = useMemo(
    () => getPastProjects(myProjects ?? []),
    [myProjects]
  );

  const futureProjects = useMemo(
    () => getComingProjects(myProjects ?? []),
    [myProjects]
  );

  const allProjects = useMemo(
    () => [...currentProjects, ...futureProjects, ...pastProjects],
    [currentProjects, futureProjects, pastProjects]
  );

  const [filteredProjects, setFilteredProjects] = useState(allProjects);

  // Use the useFilter hook to manage filters
  const { getFilteredData, updateFilters, getFilterValues, clearFilter } =
    useFilter<Project>({
      filterAliasMap,
      isValidFilterProperty: (property) =>
        ['project', 'status'].includes(property),
    });

  useEffect(() => {
    setFilteredProjects(getFilteredData(allProjects));
  }, [getFilteredData, setFilteredProjects, allProjects]);

  const toggleViewButtonContent = [
    {
      value: PROJECTS_VIEWS.CARD,
      content: <SpaceDashboardOutlinedIcon />,
      buttonProps: { onClick: () => navigate(PROJECTS_VIEWS.CARD) },
    },
    {
      value: PROJECTS_VIEWS.LIST,
      content: <ViewHeadlineIcon />,
      buttonProps: { onClick: () => navigate(PROJECTS_VIEWS.LIST) },
    },
    {
      value: PROJECTS_VIEWS.MAP,
      content: <LocationOnIcon />,
      buttonProps: { onClick: () => navigate(PROJECTS_VIEWS.MAP) },
    },
  ];

  return (
    <Grid container direction="row">
      <Breadcrumb />
      <Grid item container alignItems="center" justifyContent="space-between">
        <Typography variant="h4">{t('entities:project.namePlural')}</Typography>
        {user && canCreateProjects(user.role) && (
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => navigate('/projects/new')}
              sx={{ width: '100%' }}
            >
              {t('projects:overview.buttons.newProject')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        item
        spacing={2}
        py={4}
        alignItems="center"
        justifyContent={
          width === 'xs' || width === 'sm' ? 'flex-end' : 'space-between'
        }
      >
        {myProjects && (
          <>
            <ProjectsFilter
              getFilterValues={getFilterValues}
              updateFilters={updateFilters}
              clearFilter={clearFilter}
            />

            <Grid item>
              <ToggleButtonGroup
                selectedValue={projectsView}
                buttons={toggleViewButtonContent}
                exclusive
                onChange={(value) => setProjectView(value as string)}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Outlet context={{ filteredProjects, favouriteProjects }} />
    </Grid>
  );
};
